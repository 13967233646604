import React, { useState } from 'react';
import { Button, Modal, Form } from 'semantic-ui-react';

const TaskModal = ({ isOpen, onClose, onSubmit }) => {
  const [reason, setReason] = useState('');

  const handleInputChange = (e, { value }) => {
    setReason(value);
  };

  const handleSubmit = () => {
    onSubmit(reason);
    setReason(''); // Clear input after submission
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose} size="tiny">
      <Modal.Header>Please provide context, additional information, or any specific instructions related to the task handover.</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.TextArea
            label="Additional information / instructions"
            placeholder="Enter your instructions here..."
            value={reason}
            style={{resize: "none"}}
            onChange={handleInputChange}
            required={true}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="white" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={reason === ''}
          className='handover-button'
          content="Submit"
          onClick={handleSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default TaskModal;
